import { restaurantPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { Icon } from '../icons';
import { Link } from '../link';
import { navigation } from '../../constants';
import { P } from '../typography';

const RestaurantStatus = ({ restaurant, showClosestRestaurant, displayAsList, displayHeaderBar, ...rest }) => {
  const { latitude, longitude, isOpenText, isOpen } = restaurant;

  // https://app.clickup.com/t/860rh1hp2
  const restaurantIsHighpoint280 = restaurant.id === '280';
  const tableOrderingEnabled = restaurant.tableOrderingEnabled || restaurantIsHighpoint280;
  const deliveryEnabled = restaurant.deliveryEnabled || restaurantIsHighpoint280; // delivery intentionally left disabled for unihill
  const pickUpEnabled = restaurant.pickUpEnabled || restaurantIsHighpoint280;
  const orderingEnabled = restaurant.orderingEnabled || restaurantIsHighpoint280;

  const isRestaurantOpenAndTakeOrders = !!(orderingEnabled && isOpen);
  const spacing = { xs: '1rem', md: '.5rem' };

  return (
    <Flex color={isOpen ? 'success800' : 'error500'} {...rest}>
      {!displayAsList && (
        <Box>
          <Icon
            name="playButton"
            fill="currentColor"
            mr="0.5rem"
            position="relative"
            height="19px"
            weight="19px"
            top={{ xs: '-2px', md: '2px' }}
          />
        </Box>
      )}
      <Flex flexDirection="column">
        <Flex>
          {displayAsList && (
            <Icon
              name="playButton"
              fill="currentColor"
              mr="0.5rem"
              position="relative"
              height="16px"
              weight="16px"
              top={{ xs: '2px', md: '4px' }}
            />
          )}
          <P
            mx={displayHeaderBar ? 'auto' : undefined}
            variant={displayAsList ? 2 : 1}
            fontWeight="normal"
            color="currentColor"
            mb={spacing}
          >
            {isOpenText}
          </P>
        </Flex>
        {!displayHeaderBar && (
          <P fontWeight="normal" color="grey600" mb={spacing} variant={2}>
            Services:
          </P>
        )}
        <Flex gap={1}>
          <Flex alignItems="center">
            <StyledIcon
              name={tableOrderingEnabled && isOpen ? 'tick' : 'cross'}
              position="relative"
              fill={tableOrderingEnabled && isOpen ? 'success800' : 'error500'}
            />
            <P fontWeight="normal" color="grey600" variant={3}>
              Dine in
            </P>
          </Flex>
          <Flex alignItems="center">
            <StyledIcon
              name={pickUpEnabled && isRestaurantOpenAndTakeOrders ? 'tick' : 'cross'}
              position="relative"
              fill={pickUpEnabled && isRestaurantOpenAndTakeOrders ? 'success800' : 'error500'}
            />
            <P fontWeight="normal" color="grey600" variant={3}>
              Pick Up
            </P>
          </Flex>
          <Flex alignItems="center">
            <StyledIcon
              name={deliveryEnabled && isRestaurantOpenAndTakeOrders ? 'tick' : 'cross'}
              position="relative"
              fill={deliveryEnabled && isRestaurantOpenAndTakeOrders ? 'success800' : 'error500'}
            />
            <P fontWeight="normal" color="grey600" variant={3}>
              Delivery
            </P>
          </Flex>
        </Flex>
        {!isOpen && showClosestRestaurant && (
          <Flex pt={spacing}>
            <Link
              fontWeight="normal"
              href={{
                pathname: `${navigation.RESTAURANTS}/search`,
                query: { lat: latitude, lng: longitude, displayRestaurant: 3 },
              }}
            >
              View other restaurants
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
const StyledIcon = styled(Icon)`
  margin-right: 8px;
  min-height: 18px;
  min-width: 18px;
`;

RestaurantStatus.defaultProps = {
  showClosestRestaurant: false,
  displayAsList: false,
  displayHeaderBar: false,
};

RestaurantStatus.propTypes = {
  restaurant: restaurantPropTypes.isRequired,
  showClosestRestaurant: PropTypes.bool,
  displayAsList: PropTypes.bool,
  displayHeaderBar: PropTypes.bool,
};

export { RestaurantStatus };
