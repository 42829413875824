import { convertToSlug } from '../convert-to-slug';
import { navigation } from '../../constants';

const getRestaurantPath = restaurant => {
  if (!restaurant || !restaurant.address || !restaurant.address.state || !restaurant.name) {
    return `${navigation.RESTAURANTS}`;
  }

  const path = `${navigation.RESTAURANTS}/${convertToSlug(restaurant.address.state)}/${convertToSlug(restaurant.name)}`;

  return path;
};

export default getRestaurantPath;
