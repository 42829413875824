import { restaurantsArrayPropTypes, selectedLocationPropTypes } from '@nandosaus/prop-types';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { ORDER_TYPES } from '@nandosaus/constants';
import { Box } from '../grid';
import { Icon } from '../icons';
import { P } from '../typography';
import { RestaurantListItem } from './restaurant-list-item';
import getRestaurantPath from '../../utils/get-restaurant-path';

const RestaurantsList = ({ orderType, coordinates, onRestaurantClick, restaurants, variant, showRestaurantStatus }) => {
  return (
    <>
      {restaurants.map(restaurant => {
        const isButtonVariant = variant === 'button';
        // what is the orderType when nothing has been saved
        const isDisabled = !restaurant?.getIsAcceptingOrders({ orderType }) || false;

        const content = (
          <>
            <Box pt="0.25rem" mx="0.5rem" textAlign="center">
              <Icon
                name="locationMarker"
                width="40px"
                opacity={isButtonVariant && isDisabled ? 0.5 : 1}
                fill="currentColor"
              />
              {coordinates && (
                <P as="span" display="block" mt="0.1rem" variant={4}>
                  {restaurant.formattedDistanceToRestaurant(coordinates)}
                </P>
              )}
            </Box>
            <RestaurantListItem
              coordinates={coordinates}
              onRestaurantClick={onRestaurantClick}
              restaurant={restaurant}
              showRestaurantStatus={showRestaurantStatus}
            />
          </>
        );

        if (onRestaurantClick) {
          return (
            <ListItem
              as="button"
              variant={variant}
              disabled={isDisabled}
              onClick={() => onRestaurantClick(restaurant)}
              key={restaurant.name}
            >
              {content}
            </ListItem>
          );
        }

        return (
          <Link href={`${getRestaurantPath(restaurant)}`} passHref key={restaurant.name}>
            <ListItem as="a" variant={variant}>
              {content}
            </ListItem>
          </Link>
        );
      })}
    </>
  );
};

const ListItem = styled.div`
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ variant }) => (variant === 'button' ? null : 'white')};
  display: flex;
  text-align: left;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  text-decoration: none;
  border: solid 1px transparent;

  &:disabled {
    color: ${({ theme }) => theme.colors.grey500};
  }

  &:is(button:not(:disabled), a) {
    &:hover {
      border: solid 1px ${({ theme }) => theme.colors.black};
    }
  }

  ${({ variant }) =>
    variant === 'card' &&
    css`
      padding: 0.5rem 0rem;
      color: ${({ theme }) => theme.colors.black};
      padding-right: 1rem;
      :not(:last-child) {
        margin-bottom: 0.5rem;
      }
    `};
`;

RestaurantsList.propTypes = {
  coordinates: selectedLocationPropTypes,
  onRestaurantClick: PropTypes.func.isRequired,
  restaurants: restaurantsArrayPropTypes.isRequired,
  variant: PropTypes.oneOf(['button', 'card']),
  showRestaurantStatus: PropTypes.bool,
  orderType: PropTypes.oneOf([ORDER_TYPES.PICK_UP, ORDER_TYPES.DINE_IN, ORDER_TYPES.DELIVERY]).isRequired,
};

RestaurantsList.defaultProps = {
  coordinates: undefined,
  showRestaurantStatus: false,
  variant: 'button',
};

export { RestaurantsList };
