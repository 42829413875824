import { restaurantPropTypes, selectedLocationPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '../../grid';
import { RestaurantDetails } from '../../restaurant-details';

const RestaurantListItem = ({ coordinates, restaurant, onRestaurantClick, showRestaurantStatus }) => (
  <Box width="100%">
    <RestaurantDetails
      coordinates={coordinates}
      onRestaurantClick={onRestaurantClick}
      restaurant={restaurant}
      showRestaurantStatus={showRestaurantStatus}
      alignServicesLeft
    />
  </Box>
);

RestaurantListItem.propTypes = {
  coordinates: selectedLocationPropTypes,
  onRestaurantClick: PropTypes.func.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  showRestaurantStatus: PropTypes.bool,
};

RestaurantListItem.defaultProps = {
  coordinates: undefined,
  showRestaurantStatus: false,
};

export { RestaurantListItem };
