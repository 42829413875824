import { observer } from 'mobx-react';
import { restaurantPropTypes, selectedLocationPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Flex } from '../grid';
import { Button } from '../button';
import { P, Subheading } from '../typography';
import { RestaurantStatus } from '../restaurant-status';

const RestaurantDetails = ({ coordinates, onRestaurantClick, restaurant, type, linkText, showRestaurantStatus }) => {
  const { address, formattedDistanceToRestaurant, name } = restaurant;
  const formattedStatusMessage = restaurant.getFormattedStatusMessage();

  return (
    <>
      <Subheading as="span" display="block" variant={3}>
        {name}
      </Subheading>
      <P as="span" display="block" variant={3} {...(showRestaurantStatus && { mt: '0.5rem' })}>
        {address.address1}, {address.suburb} {address.state}
      </P>
      {formattedStatusMessage && (
        <>
          {type === 'list' &&
            (showRestaurantStatus ? (
              <RestaurantStatus showClosestRestaurant={false} restaurant={restaurant} mt="0.5rem" displayAsList />
            ) : (
              <P as="span" display="block" mt="0.25rem" variant={4} color="grey300">
                {formattedStatusMessage.subtitle}
              </P>
            ))}

          {type !== 'list' && (
            <Box>
              {showRestaurantStatus ? (
                <RestaurantStatus showClosestRestaurant={false} restaurant={restaurant} mt="0.5rem" displayAsList />
              ) : (
                <Flex my="0.5rem" justifyContent="space-between" flexWrap="wrap">
                  <Flex>
                    {coordinates && (
                      <P as="span" display="block" variant={3} color="greyPrimary" mr="1rem">
                        {formattedDistanceToRestaurant(coordinates)}
                      </P>
                    )}
                    <P as="span" display="block" variant={3} color="grey500">
                      {formattedStatusMessage.subtitle}
                    </P>
                  </Flex>
                </Flex>
              )}
              <Button variant="link" textVariant={3} onClick={() => onRestaurantClick(restaurant)}>
                {linkText}
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

RestaurantDetails.propTypes = {
  coordinates: selectedLocationPropTypes,
  linkText: PropTypes.string,
  onRestaurantClick: PropTypes.func.isRequired,
  restaurant: restaurantPropTypes.isRequired,
  type: PropTypes.string,
  showRestaurantStatus: PropTypes.bool,
};

RestaurantDetails.defaultProps = {
  coordinates: undefined,
  linkText: undefined,
  type: 'list',
  showRestaurantStatus: false,
};

const ComposedRestaurantDetails = observer(RestaurantDetails);
export { ComposedRestaurantDetails as RestaurantDetails };
